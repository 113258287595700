<template>
  <div class="video-wrapper">
    <video autoplay muted playsinline loop>
      <source src="../assets/img/rain.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <!-- Overlay -->

    <div class="header">
      <img alt="MOS Logo" src="../assets/img/herologo.png" width="500em"
        class="shadow-1 rounded-4 position-relative" />

      <!-- Content -->
      <div class="container position-relative zindex-100 header">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">

            <!-- Subtitle -->
            <h4 class="h1 font-bold text-white mt-8 mb-8">A NEW ERA OF CLUBBING IS HERE:<br>REIMAGINED, REARRANGED & REMIXED.</h4>
            <h5 class="h2 font-semibold text-white mt-8 mb-8 font-italic">AUSTRALIA & NZ 2023</h5> 
            <!-- Buttons -->
              <a href="#buy" class="btn btn-lg btn-primary color-a mx-2 px-lg-8">
                Buy Tickets
              </a>
              <a href="#signup" class="btn btn-lg btn-light mx-2 px-lg-8">
                Sign Up
              </a>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>