<template>
  <div class="pt-12 pb-12">
    <div class="container max-w-screen-xl">
      <div class="row pb-12">
        <div class="col-lg-12 text-center"></div>
        <div class="ratio ratio-4x3">
          <iframe class="p-2" width="560" height="315" src="https://www.youtube.com/embed/vqM_qprhIbU"
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture"
            allowfullscreen> </iframe>
        </div>
      </div>


      <div class="row g-4">
        <div class="col-6 ">
          <div class="row g-4 mb-4">
            <div class="col-6">
              <img src="../assets/img/image4.jpg" class="img-fluid rounded-3" alt="..." loading="lazy">
            </div>
            <div class="col-6">
              <img src="../assets/img/image2.jpg" class="img-fluid rounded-3" alt="..." loading="lazy">
            </div>
          </div>
          <img src="../assets/img/image3.jpg" class="img-fluid rounded-3" alt="..." loading="lazy">
        </div>
        <div class="col-6">
          <img src="../assets/img/image5.jpg" class="img-fluid rounded-3" alt="..." loading="lazy">
          <div class="row mt-4">
            <div class="col-6">
              <img src="../assets/img/image6.jpg" class="img-fluid rounded-3" alt="..." loading="lazy">
            </div>
            <div class="col-6">
              <img src="../assets/img/image7.jpg" class="img-fluid rounded-3" alt="..." loading="lazy">
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>