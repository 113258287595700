<template>
    <div class="modal" id="modal_signup" tabindex="-1" aria-labelledby="modal_signup" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content shadow-4 bg-black">
                <div class="d-flex flex-column">
                    <div class="text-center">
                        <h3 class="mb-4 mt-4 text-white text-2xl">Register for Updates</h3>
                        <p class="text-white text-md">
                            Stay up to date with upcoiming show information for your city.
                        </p>
                    </div>
                </div>
                <div class="modal-body">
                    <form class="js-cm-form container needs-validation" id="subForm"
                        action="https://www.createsend.com/t/subscribeerror?description=" method="post"
                        data-id="A61C50BEC994754B1D79C5819EC1255CE6668EA186A1F74C639B53DB5273456BF9C27EF9E6ABC5F8E009C3CC0285255FEF880D0DA50918A5E772C4A63C56AF02">
                        <div class="row g-2 pt-3 pb-1">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="fieldName" placeholder="Full Name"
                                        name="cm-name" required="true"
                                        oninvalid="this.setCustomValidity('Please Enter Your Name')"
                                        onvalid="this.setCustomValidity('')">
                                    <label for="name" class="form-label">Name</label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="email" class="form-control js-cm-email-input qa-input-email"
                                        id="fieldEmail" placeholder="name@example.com" name="cm-jhahtu-jhahtu"
                                        required="true"
                                        oninvalid="this.setCustomValidity('Please Enter a Valid Email Address')"
                                        onvalid="this.setCustomValidity('')">
                                    <label for="email" class="form-label">Email address</label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="number" class="form-control" id="fieldiiohdd" placeholder="Mobile"
                                        name="cm-f-iiohdd">
                                    <label for="floatingInputGrid">Mobile Phone</label>
                                </div>
                            </div>

                        </div>
                        <div class="row g-2 pt-1 pb-1">
                            <div class="col-md">
                                <div class="form-floating">
                                    <input type="text" class="form-control" id="fieldiiohdt" placeholder="Your City"
                                        name="fieldiiohdt" required="true"
                                        oninvalid="this.setCustomValidity('Please Enter Your City')"
                                        onvalid="this.setCustomValidity('')">
                                    <label for="floatingInputGrid">City</label>
                                </div>
                            </div>

                            <div class="col-md pb-2">
                                <div class="form-floating">
                                    <select class="form-select" id="fieldiiqch" aria-label="State (Required)"
                                        name="cm-fo-iiqch" required="true">
                                        <option selected></option>
                                        <option value="6357229">ACT</option>
                                        <option value="6357227">NSW</option>
                                        <option value="6357228">VIC</option>
                                        <option value="6357230">QLD</option>
                                        <option value="6357231">WA</option>
                                        <option value="6357232">TAS</option>
                                        <option value="6357233">NT</option>
                                        <option value="6357234">SA</option>
                                        <option value="6690241">NZ</option>
                                    </select>
                                    <label for="floatingSelectGrid">Select Your State</label>
                                </div>

                            </div>
                        </div>

                        <button type="submit" class="btn btn-animated btn-primary btn-animated-y mt-4">
                            <div class="btn-inner-visible">SUBMIT</div>
                            <div class="btn-inner-hidden">
                                <span class="">
                                    <i class="bi bi-send"></i>
                                </span>
                            </div>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>