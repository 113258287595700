<template>
  <AppHeader />
  <EventList />
  <ImageGallery />
  <SignupForm />
  <PageFooter />
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import EventList from './components/EventList.vue';
import ImageGallery from './components/ImageGallery.vue';
import SignupForm from './components/SignupForm.vue';
import PageFooter from './components/PageFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    EventList,
    ImageGallery,
    SignupForm,
    PageFooter
}
}
</script>

<style>
#app {
  font-family: Gilroy-Bold, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
</style>



