<template>
  <div class="py-6 mb-5">
    <div class="container max-w-screen-xl">
      <div class="row justify-content-center text-center mt-2">
        <div class="col-lg-7">
          <h1 class="ls-tight font-bolder text-white display-5 pb-4" id="buy">
            UPCOMING SHOWS
          </h1>
          <p class="lead text-muted">
          </p>
        </div>
      </div>
  
      <!--HIGHLIGHT EVENTS CONTAINER-->
      <div class="container px-1">
        <div class="row g-5">
  
  
          <div class="col-lg-4">
            <div class="card cardbg">
              <a :href="city_1.url">
                <img src="../assets/img/classical13.jpg" alt="..." class="card-img">
              </a>
              <div class="card-body">
                <p class="h3 d-block text-white text-uppercase">{{city_1.city}}</p>
                <p class="text-white text-md text-uppercase">{{ city_1.date }}</p>
                <div class="">
                  <a :href="city_1.url" class="btn d-inline-flex btn-dark">
                    <span class="text-uppercase">{{city_1.button}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-lg-4">
            <div class="card cardbg">
              <a :href="city_2.url">
                <img src="../assets/img/classical5.jpg" alt="..." class="card-img">
              </a>
              <div class="card-body">
                <p class="h3 d-block text-white text-uppercase">{{ city_2.city }}</p>
                <p class="text-white text-md">{{ city_2.date }}</p>
                <div class="">
                  <a :href="city_2.url" class="btn d-inline-flex btn-dark">
                    <span class="text-uppercase">{{city_2.button}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-lg-4">
            <div class="card cardbg">
              <a href="#">
                <img src="../assets/img/classical17.jpg" alt="..." class="card-img">
              </a>
              <div class="card-body">
                <p class="h3 d-block text-white text-uppercase">{{ city_3.city }}</p>
                <p class="text-white text-md">{{ city_3.date }}</p>
                <div class="">
                  <a href="#" class="btn d-inline-flex btn-dark" type="button" data-bs-toggle="modal"
                    data-bs-target="#modal_signup">
                    <span class="text-uppercase">{{city_3.button}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-lg-4">
            <div class="card cardbg">
              <a href="#">
                <img src="../assets/img/classical11.jpg" alt="..." class="card-img">
              </a>
              <div class="card-body">
                <p class="h3 d-block text-white text-uppercase">{{ city_4.city }}</p>
                <p class="text-white text-md">{{ city_4.date }}</p>
                <div class="">
                  <a href="#" class="btn d-inline-flex btn-dark" type="button" data-bs-toggle="modal"
                    data-bs-target="#modal_signup">
                    <span class="text-uppercase">{{city_4.button}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
  
          <div class="col-lg-4">
            <div class="card cardbg">
              <a href="#">
                <img src="../assets/img/classical12.jpg" alt="..." class="card-img">
              </a>
              <div class="card-body">
                <p class="h3 d-block text-white text-uppercase">{{ city_5.city }}</p>
                <p class="text-white text-md">{{ city_5.date }}</p>
                <div class="">
                  <a href="#" class="btn d-inline-flex btn-dark" type="button" data-bs-toggle="modal"
                    data-bs-target="#modal_signup">
                    <span class="text-uppercase">{{city_5.button}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-lg-4">
   
            <div class="card cardbg">
              <a href="#">
                <img src="../assets/img/classical15.jpg" alt="..." class="card-img">
              </a>
              <div class="card-body">
                <p class="h3 d-block text-white text-uppercase">{{ city_6.city }}</p>
                <p class="text-white text-md">{{ city_6.date }}</p>
                <div class="">
                  <a href="#" class="btn d-inline-flex btn-dark" type="button" data-bs-toggle="modal"
                    data-bs-target="#modal_signup">
                    <span class="text-uppercase">{{city_6.button}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
  
        </div>
      </div>
    </div>
  </div>

<!-- END HIGHLIGHT EVENTS CONTAINER-->


<Signup_Modal/>


</template>

<script>
import Signup_Modal from './Signup_Modal.vue';



export default {
    data() {
        return {

          city_1: {
            city: 'Sydney',
            date: 'Mar 28',
            button: 'Buy Tickets',
            url: 'https://moshtix.com.au/v2/event/ministry-of-sound-classical-sydney/148334'
          },

          city_2: {
            city: 'Gold Coast',
            date: '2023',
            button: 'Register for Presale',
            url: 'https://arep.co/p/mosc-goldcoast'
          },

          city_3: {
            city: 'Adelaide',
            date: '2023',
            button: 'Join the Waitlist',
            url: '',
          },

          city_4: {
            city: 'Brisbane',
            date: '2023',
            button: 'Join the Waitlist',
            url: '',
          },

          city_5: {
            city: 'Melbourne',
            date: '2023',
            button: 'Join the Waitlist',
            url: '',
          },

          city_6: {
            city: 'Perth',
            date: '2023',
            button: 'Join the Waitlist',
            url: '',
          },











    }
  },
  components: { Signup_Modal }
  
}

</script>






