<template>
    <footer class="bg-black container">
            <div class="row align-items-center">
                <div class="col-md-12">
                            <a class="text-muted pb-2" href="https://www.tmrw.com.au">
                                Copyright TMRW Music {{ currentYear }}
                            </a>
                </div>
            </div>
    </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  }
};

</script>